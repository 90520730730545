// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-algemene-voorwaarden-js": () => import("./../../../src/pages/algemene-voorwaarden.js" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kindertekeningen-js": () => import("./../../../src/pages/kindertekeningen.js" /* webpackChunkName: "component---src-pages-kindertekeningen-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-recensies-js": () => import("./../../../src/pages/recensies.js" /* webpackChunkName: "component---src-pages-recensies-js" */),
  "component---src-pages-specialisaties-js": () => import("./../../../src/pages/specialisaties.js" /* webpackChunkName: "component---src-pages-specialisaties-js" */),
  "component---src-pages-tarieven-js": () => import("./../../../src/pages/tarieven.js" /* webpackChunkName: "component---src-pages-tarieven-js" */),
  "component---src-pages-werkwijze-js": () => import("./../../../src/pages/werkwijze.js" /* webpackChunkName: "component---src-pages-werkwijze-js" */),
  "component---src-pages-wie-ben-ik-js": () => import("./../../../src/pages/wie-ben-ik.js" /* webpackChunkName: "component---src-pages-wie-ben-ik-js" */)
}

